import * as THREE from 'three';
import * as MATH from './mymath.js';
import { CSS2DRenderer, CSS2DObject } from './CSS2DRenderer.js';
import { TrackballControls } from './trackball.js';
import { Colors } from './colors.js';
import { Simplex, braidArrangement } from './geometry.js';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import './css/tailwind/before.css';
import './css/style.css';
import './css/tailwind/after.css';

// Based on https://sbcode.net/threejs/animate-on-scroll/
if ( window.screen.width < 1280 ) {
    document.getElementById('softmax-weights-mobile').style.visibility = 'hidden';
}

window.addEventListener('load', function() {
    document.getElementById('content').style.visibility = "";
    window.scrollTo(0, 1);
})

let scrollPercent = 0;
window.onscroll = () => {
    //calculate the current scroll progress as a percentage
    scrollPercent =
        ((document.documentElement.scrollTop || document.body.scrollTop) /
            ((document.documentElement.scrollHeight ||
                document.body.scrollHeight) -
                document.documentElement.clientHeight)) *
        100
    ;
    (document.getElementById('scrollProgress')).innerText =
        '' + scrollPercent.toFixed(2) + '%';
    playScrollAnimations();
}

let animationScripts = [];

function playScrollAnimations() {
    for ( let i = 0; i < animationScripts.length ; i++ ) {
        // Guarantee that if scrolling is too fast
        // we will not be left in an inconsistent state
        let a = animationScripts[i];
        if (scrollPercent > a.end && !a.activated) {
            let ff = a.func.bind(a);
            ff(1.);
            a.activated = true;
        }
        // Guarantee that if unscrolling is too fast
        // we will not be left in an inconsistent state
        else if (scrollPercent < a.start && a.activated) {
            let ff = a.func.bind(a);
            a.activated = false;
            ff(0.);
        }
        // Deal with current active scrollable rule
        else if (scrollPercent >= a.start && scrollPercent < a.end) {
            const len = a.end - a.start;
            let perc = (scrollPercent - a.start) / len;
            let ff = a.func.bind(a);
            ff(perc);
        }
    }
}

function createControls( camera ) {

    let controls = new TrackballControls( camera, renderer.domElement );

    controls.rotateSpeed = 4.0;
    controls.zoomSpeed = 1.5;
    controls.panSpeed = 0.8;

    controls.keys = [ 65, 83, 68 ];
    return controls;

}


function termial(i) {
    if ( i < 1 ) {
        return 0;
    }
    else {
        return i * ( i + 1 ) / 2;
    }
}


class ComparisonButtons {
    constructor ( numClasses ) {
        this.numClasses = numClasses;
    }
    
    generateHTML ( ) {
        const container = document.createElement( 'div' );
		container.className = 'comparison-buttons';
		container.id = 'comparison-buttons';
        let labels = ['a', 'b', 'c', 'd'];
        for ( let i = 0; i < this.numClasses; i++ ) {
            for ( let j = 0; j < this.numClasses; j++ ) {
                // Remove i == 3 to print all constraints
                if ( i != j && (i == 3) ) { 
                    const btn = document.createElement( 'button' );
                    let pos;
                    if ( i > j ) {
                        pos = j * (numClasses - 1) - termial(j) + i - 1;
                    }
                    else {
                        pos = i * (numClasses - 1) - termial(i) + j - 1;
                    }
                    btn.className = 'btn btn-comparison';
                    btn.id = 'btn-' + labels[i] + '-' + labels[j];
                    btn.innerText = labels[i] + ' > ' + labels[j];
                    btn.addEventListener('click', function( ) {
                        if ( btn.classList.contains('toggled') ) {
                            btn.classList.remove('toggled');
                            for ( let k = 0; k < dataset.length(); k++) {
                                let plane;
                                if ( i < j ) {
                                    plane = ws.braid_weights[pos].hyperplane.get_plane();
                                }
                                else {
                                    plane = ws.braid_weights[pos].hyperplane.get_neg_plane();
                                }
                                const index = dataset.points[k].material.clippingPlanes.indexOf(plane);
                                dataset.points[k].material.clippingPlanes.splice(index, 1);
                            }
                        }
                        else {
                            btn.classList.add('toggled');
                            for ( let k = 0; k < dataset.length(); k++) {
                                let plane;
                                if ( i < j ) {
                                    plane = ws.braid_weights[pos].hyperplane.get_plane();
                                }
                                else {
                                    plane = ws.braid_weights[pos].hyperplane.get_neg_plane();
                                }
                                if ( dataset.points[k].material.clippingPlanes == null ) {
                                    dataset.points[k].material.clippingPlanes = [];
                                }
                                dataset.points[k].material.clippingPlanes.push(plane);
                            }
                        }
                    });
                    container.appendChild(btn);
                }
            }
        }

        const parent = document.getElementById('comparison-tab');
        parent.replaceChildren();

        const title = document.createElement( 'span' );
        title.innerText = 'Constraints';
        parent.appendChild(title);
        parent.appendChild(container);
    }

    clearHTML ( ) {
        const parent = document.getElementById('comparison-tab');
        parent.replaceChildren();
        for ( let k = 0; k < dataset.length(); k++) {
            dataset.points[k].material.clippingPlanes = [];
        }
    }
}


class RegionNums {
    constructor ( numRegions ) {
        this.numRegions = numRegions;
    }
    
    generateMathJax ( ) {
        const mathjaxi = document.createElement( 'div' );
		mathjaxi.className = 'mathjaxi';
		mathjaxi.id = 'mathjaxi';
        mathjaxi.style.display = 'none';
        for ( let i = 0; i < this.numRegions; i++ ) {
            const label = document.createElement( 'div' );
            const num = document.createElement( 'div' );
            num.className = 'region-num';
            num.innerText = 'R';
            const sub = document.createElement( 'sub' );
            sub.innerText = i;
            label.id = 'regionNum' + i;
            // num.appendChild(sub);
            // label.appendChild(num);
            label.className = 'region-num-label';
            mathjaxi.appendChild(label);
        }
		return mathjaxi;
    }
}


class RegionLabel {
    constructor ( bitstring, colors, number ) {
        this.bitstring = bitstring;
        this.colors = colors;
        this.labels = ['a', 'b', 'c', 'd'];
        this.number = number;
        this.perm = this.decodeBitstring();
    }

    // Convert bitstring encoding permutation as an inversion set
    // with n choose 2 bits to to a permutation [n] representation
    decodeBitstring ( ) {
        // n choose 2 is n * (n - 1) / 2
        // so 2 * permlength = n^2 - n
        // so sqrt(2 * permlength) = n * sqrt(1 - 1/n)
        // For n >=2 Math.ceil(sqrt(1 - 1/n)) = 1.
        const permLength = Math.floor(Math.sqrt(this.bitstring.length * 2)) + 1;
        let perm = MATH.range(permLength);
        let idx = 0;
        // In what follows we effectively undo the inversion
        // Hence we sort.
        // What we obtain is the inverse permutation
        for ( let i = 0; i < permLength; i++ ) {
            for ( let j = i + 1; j < permLength; j++ ) {
                if ( parseInt(this.bitstring[idx]) ) {
                    // Swap elements that are out of order
                    perm[i]++;
                    perm[j]--;
                }
                idx++;
            }
        }
        // Compute inverse of inverse permutation.
        let out = Array(permLength);
        for ( let i = 0; i < permLength; i++ ) {
            out[perm[i]] = i;
        }
        return out;
    }

    buildLabel ( ) {
        const order = this.perm;
        const label = document.createElement( 'div' );
        label.className = 'region-label';

        const num = document.getElementById('regionNum' + this.number).cloneNode(true);
        // num.style.visibility = 'visible';
        label.appendChild(num);
        // Reverse order of colours as we read left to right..
        // This means max is at left
        const circleSizeClasses = ['circle-1', 'circle-2', 'circle-3', 'circle-4']
        let idx = 0;
        for ( const i of order.reverse() ) {
            const circle = document.createElement( 'div' );
            if ( idx == (order.length - 1) ) {
                circle.className = 'circle circle-last';
            }
            else {
                circle.className = 'circle ' + circleSizeClasses[idx];
            }
            let color = new THREE.Color(this.colors[i]);
            circle.innerText = this.labels[i];
            circle.style.backgroundColor = '#' + color.getHexString();
            label.appendChild(circle);
            idx++;
        }
        return label;
    }
}


class RandomDataset {
    constructor (size, dim, scale) {
        this.size = size;
        this.dim = dim;
        this.scale = scale;
        const geometry = new THREE.SphereGeometry(this.scale, 8, 8);
        const material = new THREE.MeshStandardMaterial( { color: 0xff5555, transparent: true } );

        this._points = new THREE.Group();
        this.points = this._points.children;
        this.generate_data();
        for (let i = 0; i < this.positions.length; i++) {

            const [x, y, z] = this.positions[i];
            let sphere = new THREE.Mesh(geometry, material);

            sphere.position.x = x;
            sphere.position.y = y;
            sphere.position.z = z - .2;

            // Allow each point to be colored independently
            sphere.material = sphere.material.clone();

            this._points.add( sphere );
        }
    }

    generate_data () {
        const data = [];
        for ( let j = 0; j < this.size; j++ ) {
            let row = [];
            for ( let i = 0; i < this.dim; i++ ) {
                row.push((Math.random() - .5) * 5);
            }
            for ( let i = this.dim; i < 3; i++ ) {
                row.push(0);
            }
            data.push(row);
        }
        this.positions = data;
    }

    length ( ) {
        return this.points.length;
    }

    add_to ( scene ) {
        scene.add(this._points);
    }
}


class Dataset {
    constructor (data, scale) {
        this.data = [];
        for (let row of data) {
            this.data.push(row.slice());
        }
        this.scale = scale;
        const geometry = new THREE.SphereGeometry(this.scale, 8, 8);
        const material = new THREE.MeshStandardMaterial( { color: 0xff5555, transparent: true } );

        this._points = new THREE.Group();
        this.points = this._points.children;
        for (let i = 0; i < this.data.length; i++) {

            let row = this.data[i];
            if (row.length == 2) {
                row.push(row[1]);
            }
            if (row.length == 4) {
                let x = row[0] - row[1] - row[2] + row[3];
                let y = row[0] - row[1] + row[2] - row[3];
                let z = row[0] + row[1] - row[2] - row[3];
                row[0] = x;
                row[1] = y;
                row[2] = z;
            }
            const [x, y, z] = this.data[i];
            let sphere = new THREE.Mesh(geometry, material);

            sphere.position.x = x;
            sphere.position.y = y;
            sphere.position.z = z;

            // Allow each point to be colored independently
            sphere.material = sphere.material.clone();

            this._points.add( sphere );
        }
    }

    length ( ) {
        return this.points.length;
    }

    add_to ( scene ) {
        scene.add(this._points);
    }
}


class Permutohedron {
    constructor (position, dim) {

        this.position = position;
        this.dim = dim;

        if ( this.dim == 4 ) {
			this.scale = Math.sqrt(5);
            this.positions = [[ 0.,  1.,  2.],
                              [ 1.,  0.,  2.],
                              [ 2.,  0.,  1.],
                              [ 2., -1.,  0.],
                              [ 2.,  0., -1.],
                              [ 2.,  1.,  0.],
                              [ 1.,  2.,  0.],
                              [ 0.,  2.,  1.],
                              [-1.,  2.,  0.],
                              [ 0.,  2., -1.],
                              [ 0.,  1., -2.],
                              [ 1.,  0., -2.],
                              [ 0., -1., -2.],
                              [-1.,  0., -2.],
                              [-2.,  0., -1.],
                              [-2.,  1.,  0.],
                              [-2.,  0.,  1.],
                              [-2., -1.,  0.],
                              [-1., -2.,  0.],
                              [ 0., -2., -1.],
                              [ 1., -2.,  0.],
                              [ 0., -2.,  1.],
                              [ 0., -1.,  2.],
                              [-1.,  0.,  2.]];
            this.faces = [ 0, 1, 2,
                           1, 3, 2,
                           0, 2, 5,
                           0, 5, 7,
                           6, 7, 5,
                           6, 10, 9,
                           6, 11, 10,
                           6, 4, 11,
                           6, 5, 4,
                           5, 2, 3,
                           5, 3, 4,
                           1, 20, 3,
                           1, 21, 20,
                           1, 22, 21,
                           0, 22, 1,
                           0, 23, 22,
                           6, 9, 7,
                           9, 8, 7,
                           9, 15, 8,
                           9, 14, 15,
                           9, 13, 14,
                           9, 10, 13,
                           13, 10, 11,
                           13, 11, 12,
                           11, 4, 3,
                           11, 3, 20,
                           11, 20, 19,
                           11, 19, 12,
                           18, 17, 14,
                           18, 14, 13,
                           18, 13, 12,
                           18, 12, 19,
                           14, 17, 16,
                           14, 16, 15,
                           19, 20, 21,
                           19, 21, 18,
                           21, 22, 23,
                           21, 23, 16,
                           21, 16, 17,
                           21, 17, 18,
                           16, 8, 15,
                           16, 7, 8,
                           16, 0, 7,
                           16, 23, 0 ];
        }
        else if ( dim == 3 ) {
			this.scale = Math.sqrt(1.5);
            this.positions = [[ -1.,  0.,  1.],
                              [ 0.,  -1.,  1.],
                              [ 1.,  -1.,  0.],
                              [ 1.,  0.,  -1.],
                              [ 0.,  1.,  -1.],
                              [ -1.,  1.,  0.]];
            this.faces = [ 0, 1, 5,
                           5, 1, 4,
                           4, 1, 3,
                           3, 1, 2];
        }
		else {
			this.scale = Math.sqrt(2);
            this.positions = [[ -1.,  0.,  1.],
                              [ 1.,  0.,  -1.]];
            this.faces = [];
		}
        const geometry = new THREE.SphereGeometry(.18, 8, 8);
        const material = new THREE.MeshLambertMaterial( { color: 0x55cc55, transparent: true } );
        this.group = new THREE.Group();
        this.labels = new THREE.Group();
        this.vertices = new THREE.Group();
        for (let i = 0; i < this.positions.length; i++) {

            const [x, y, z] = this.positions[i];
            let sphere = new THREE.Mesh(geometry, material);

            sphere.position.x = x;
            sphere.position.y = y;
            sphere.position.z = z;

            // Allow each vertex to be colored independently
            sphere.material = sphere.material.clone();

            this.vertices.add( sphere );
        }
        this.vertexLookup = this.computeLabels();
        this.group.add(this.vertices);
        this.group.add(this.labels);
        this.group.add(this.construct_polytope());
    }

    computeLabels () {
        // Normal vectors of the braid arrangement
        let normals;
        if ( this.dim == 4 ) {
            normals = [[1, 1, 0],
					   [1, 0, 1],
					   [0, 1, 1],
					   [0, -1, 1],
					   [-1, 0, 1],
					   [-1, 1, 0]];
            // normals = [[1, -1, 0],
			// 		   [1, 0, -1],
			// 		   [0, -1, -1],
			// 		   [0, 1, -1],
			// 		   [-1, 0,-1],
			// 		   [-1, -1,0]];
        }
        else if ( this.dim == 3 ) {
            normals = [[1, -1, 0],
                       [1, 0, -1],
                       [0, 1, -1]];
        }
        else {
            normals = [[1, -1, 0]];
		}

        // Assuming no duplicates here
        let signVectors = [];
        let idx = 0;
        for (let p of this.positions) {
            const [px, py, pz] = p;
            let sign_vector = [];
            normals.forEach(function (n) {
                const [nx, ny, nz] = n;
                let dot = px * nx + py * ny + pz * nz;
                if ( dot > 0 ) {
                    sign_vector.push( 1 );
                }
                else {
                    sign_vector.push( 0 );
                }
            });
            let str_sign_vector = sign_vector.join('');
            signVectors.push(str_sign_vector);

            // const text = document.createElement( 'div' );
            // text.className = 'label';
            // text.style.color = '#000000';
            // text.textContent = '$$R_{' + idx + '}$$';
            // text.style.visibility = 'hidden';
			const num = document.getElementById('regionNum' + idx).cloneNode(true);
			// num.style.visibility = 'visible';

            let label = new CSS2DObject( num );
            label.position.copy(new THREE.Vector3(p[0], p[1], p[2]));
            this.labels.add(label);
            idx += 1;
        }

        let vertexLookup = {};
        this.vertexNumber = {};
        this.vertexLabel = {};
        for ( let i = 0; i < this.vertices.children.length; i++ ) {
            let v = this.vertices.children[i];
            vertexLookup[signVectors[i]] = v;
            this.vertexNumber[signVectors[i]] = i;
            this.vertexLabel[v.uuid] = this.labels.children[i];
        }
        return vertexLookup;
    }

    color_vertex( v, color, opacity, visible=true ) {
        const tcolor = new THREE.Color(color);
        v.material.color = tcolor;
        v.material.opacity = opacity;
        let label = this.vertexLabel[v.uuid];
        label.element.style.color = '#' + tcolor.getHexString();
        // v.visible = visible;
        label.visible = visible;
    }

    color_vertices( color, opacity, visible=true ) {
        for ( let i = 0; i < this.vertices.children.length; i++ ) {
            this.color_vertex(this.vertices.children[i], color, opacity, visible);
        }
    }

    construct_polytope() {

        // let materials = [];
        // for (let i = 0; i < geometry.faces.length; i++) {
        //     const color = parseInt(Math.random() * 0xffffff);
        //     let face = geometry.faces[i];
        //     face.color.setHex(color);
        //     // materials.push(new THREE.MeshBasicMaterial());
        // }
        let positions = [].concat(...this.positions);
        const geometry = new THREE.PolyhedronBufferGeometry( positions, this.faces, this.scale, 0 );
        const material = new THREE.MeshNormalMaterial({flatShading: true, transparent: true, opacity: 0.25});
        // const material = new THREE.MeshPhysicalMaterial({ transparent: true, opacity: 0.5, reflectivity: 0., side: THREE.BackSide});
        let object = new THREE.Mesh(geometry, material);

        const [x, y, z] = this.position;
        object.translateX(x);
        object.translateY(y);
        object.translateZ(z);

        return object;
    }

    add_to ( scene ) {
        scene.add(this.group);
    }
}


class Hyperplane {
    constructor (x, y, z, color, width=5, height=5, opacity=.5) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.color = color;
        // const material = new THREE.MeshPhysicalMaterial( {color: color, side: THREE.DoubleSide, transparent:true, opacity: opacity} );
        // const geometry = new THREE.PlaneGeometry(width, height);
        // this.mesh = new THREE.Mesh(geometry, material);
        // this.mesh.lookAt(new THREE.Vector3(x, y, z));

        const material = new MeshLineMaterial( {color: color,
                                      lineWidth: .02,
                                      transparent: true,
                                      opacity:.8
                                      } );
        // material.renderOrder = 5;
        // material.depthTest = false;
        // material.depthWrite = false;
        let meshline = new MeshLine();
        let geometry = meshline.geometry;
        this.mesh = new THREE.Mesh( geometry, material );
        this.get_plane = this.get_plane.bind(this);
        this.get_neg_plane = this.get_neg_plane.bind(this);
        // this.mesh.lookAt(new THREE.Vector3(x, y, z));

    }

    add_to (scene) {
        scene.add(this.mesh);
    }

    get_plane ( ) {
        return new THREE.Plane(new THREE.Vector3(this.x, this.y, this.z));
    }

    get_neg_plane ( ) {
        return new THREE.Plane(new THREE.Vector3(-this.x, -this.y, -this.z));
    }

    look_at (dir) {
        this.x = dir.x;
        this.y = dir.y;
        this.z = dir.z;

        const xx = dir.y;
        const yy = -dir.x
        let pos_end = new THREE.Vector3(xx, yy, 0).multiplyScalar(10.);
        let neg_end = new THREE.Vector3(xx, yy, 0).multiplyScalar(-10.);
        this.mesh.geometry.setPoints([neg_end, pos_end]);
        // this.mesh.lookAt(dir);
    }
}


class WeightVector {
    constructor (x, y, z, color=0x00ff00, label) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.color = color;
        const end = new THREE.Vector3( x, y, z );
        const dir = end.normalize();
        const origin = new THREE.Vector3( 0, 0, 0 );
        const length = this.length();
        this.head_length = .3;
        this.head_width = .15;
        this.arrow = new THREE.ArrowHelper( dir, origin, length, color, this.head_length, this.head_width);
        this.hyperplane = new Hyperplane(dir.x, dir.y, dir.z, color);

        const text = document.createElement( 'div' );
        text.className = 'w-label';
        const rep = this.rep_obj();
        text.style.color = '#' + rep.material.color.getHexString();
        text.textContent = label || 'w';

        this.label = new CSS2DObject( text );
        rep.getWorldPosition(this.label.position);

        this.group = new THREE.Group()
        this.group.add( this.arrow );
        this.hyperplane.mesh.visible = false;
        this.group.add( this.hyperplane.mesh );
        this.group.add( this.label );
    }

    length () {
        return Math.sqrt(this.x ** 2 + this.y ** 2 + this.z ** 2);
    }

    add ( other ) {
        const x = this.x + other.x;
        const y = this.y + other.y;
        const z = this.z + other.z;
        const this_color = new THREE.Color(this.color);
        const other_color = new THREE.Color(other.color);
        const comb_color = this_color.lerp(other_color, .5);
        const color = comb_color.getHex();
        return new WeightVector(x, y, z, color, this.label);
    }

    scale (value) {
        const x = this.x * value;
        const y = this.y * value;
        const z = this.z * value;
        return new WeightVector(x, y, z, this.color, this.label);
    }

    add_to (scene) {
        scene.add(this.group);
    }

    rep_obj () {
        return this.arrow.children[1];
    }

    set_hyperplane_visible () {
        this.hyperplane.mesh.visible = true;
    }

    move_to (pos) {
        let dir = new THREE.Vector3( pos.x, pos.y, pos.z);
        this.x = pos.x;
        this.y = pos.y;
        this.z = pos.z;
        dir = dir.normalize();
        this.arrow.setDirection(dir);
        this.arrow.setLength(this.length(), this.head_length, this.head_width);
        // Update location of label
        this.rep_obj().getWorldPosition(this.label.position);
        this.hyperplane.look_at(dir);
    }
}


class WeightQuiver {
    // A collection of weight vectors
    constructor ( weights ) {
        this.weights = weights;
        this.raycaster = new THREE.Raycaster();
        this._weights = new THREE.Group();

        for ( let i = 0; i < this.weights.length; i++ ) {
            let weight = this.weights[i];
            this._weights.add( weight.group );
        }
        this.braid_construction();
    }

    add (w) {
        this.weights.push(w);
    }

    add_to (scene) {
        scene.add(this._weights);
    }

    scene_candidates ( ) {
        const candidates = [];
        this.weights.forEach(function (e) {
            candidates.push(e.rep_obj());
        });
        return candidates;
    }

    find_rep (rep) {
        let found = null;
        this.weights.forEach(function (e) {
            if ( e.rep_obj().uuid === rep.uuid ) {
                found = e;
            }
        });
        if ( found == null ) {
            this.braid_weights.forEach(function (e) {
                if ( e.rep_obj().uuid === rep.uuid ) {
                    found = e;
                }
            });
        }
        return found;
    }

    pick(mouse, scene, camera) {
        let object = null;
        this.raycaster.setFromCamera(mouse, camera);

        const intersectedObjects = this.raycaster.intersectObjects(this.scene_candidates());
        if (intersectedObjects.length) {
            object = this.find_rep(intersectedObjects[0].object);
        }
        return object;
    }

    braid_construction () {
        this.braid_weights = [];
        this.braid_group = new THREE.Group();
        for ( let i = 0; i < this.weights.length; i++ ) {
            for ( let j = i + 1; j < this.weights.length; j++ ) {
                let diff = this.weights[i].add(this.weights[j].scale(-1.));
                let left = '<span style="color: #';
                left += this.weights[i].rep_obj().material.color.getHexString();
                left +=  ';">';
                left += String.fromCharCode(97 + i) + '</span>';
                let right = '<span style="color: #';
                right += this.weights[j].rep_obj().material.color.getHexString();
                right +=  ';">';
                right += String.fromCharCode(97 + j) + '</span>';
                diff.label.element.innerHTML = 'Class ' + left + ' > ' + right;
                diff.arrow.visible = false;
                diff.label.visible = false;
                diff.label.element.style.fontSize = '15px';
                diff.set_hyperplane_visible();
                this.braid_group.add(diff.group);
                this.braid_weights.push(diff);
            }
        }
        this._weights.add(this.braid_group);
    }

    braid_update () {
        let idx = 0;
        for ( let i = 0; i < this.weights.length; i++ ) {
            for ( let j = i + 1; j < this.weights.length; j++ ) {
                let diff = this.weights[i].add(this.weights[j].scale(-1.));
                let w = this.find_rep(this.braid_weights[idx].rep_obj());
                w.move_to( { x: diff.x, y: diff.y, z: diff.z } );
                w.arrow.setLength(w.length(), .15, .05);
                idx += 1;
            }
        }
        this._weights.add(this.braid_group);
    }

    hideBraid ( ) {
        this.braid_group.visible = false;
        this.braid_weights.forEach( function (c) {
            c.label.visible = false;
        });
        if (this.regionLabels !== undefined) {
            this.regionLabels.children.forEach( function (c) {
                c.visible = false;
            });
        }
    }

    showBraid ( ) {
        this.braid_group.visible = true;
        // this.braid_weights.forEach( function (c) {
        //     c.label.visible = true;
        // });
        if ( this.regionLabels !== undefined ) {
            if ( showLabels ) {
                this.regionLabels.children.forEach( function (c) {
                    c.visible = true;
                });
            }
        }
    }

	computeSignString ( p, ws ) {
		let sign_vector = [];
		ws.forEach(function (w) {
			let dot = p[0] * w.x + p[1] * w.y;
			if ( dot > 0 ) {
				sign_vector.push(1);
			}
			else if ( dot < 0 ) {
				sign_vector.push(0);
			}
			else {
				console.log('This is unlucky - point exactly on hyperplane');
			}
		});
		return sign_vector.join('');
	}

    getValidRegions ( weights ) {
        // This is hacky - only works for 2d with no offsets.
        // sensitivity of .5 degrees
        const radius = 1.8;
        const samples = 360 * 2;
        let valid_regions = new Set();
        if ( this.regionLabels !== undefined ) {
            this.braid_group.remove(this.regionLabels);
            let children = [...this.regionLabels.children];
            for ( let child of children ) {
                this.regionLabels.remove(child);
            }
        }
        this.regionLabels = new THREE.Group();

		// n-1 positions for n chambers
		let positions = [];
		const firstSign = this.computeSignString( [1, 0], weights );
		let stringSigns = [];
		let prevSign = firstSign;

        for (let i = 0; i < samples; i++) {
            // go around in a circle
            let x = Math.cos(2 * Math.PI * i / samples) * radius;
            let y = Math.sin(2 * Math.PI * i / samples) * radius;
            // dot product
			const signString = this.computeSignString( [x, y], weights );
			if ( signString != prevSign ) {
				positions.push( [x, y] );
				stringSigns.push( signString );
				valid_regions.add(signString);
				prevSign = signString;
			}
		}
		const numSigns = stringSigns.length;
        for (let i = 0; i < numSigns; i++) {
			const signString = stringSigns[i];
			const regionNumber = perm.vertexNumber[signString];
			const region = new RegionLabel(signString, colors.colors, regionNumber);
			const [xp, yp] = positions[i];
			const [xn, yn] = positions[(i + 1) % numSigns];
			let position;
			if ( stringSigns.length == 2 ) {
				if ( i == 0 ) {
					position = new THREE.Vector3(weights[0].x , weights[0].y, 0);
				}
				else {
					position = new THREE.Vector3(-weights[0].x , -weights[0].y, 0);
				}
			}
			else {
				position = new THREE.Vector3((xn + xp) / 2, (yn + yp) / 2, 0);
			}
			position = position.normalize();
			position = position.multiplyScalar(radius);
			const elem = region.buildLabel(); 
			let obj = new CSS2DObject( elem );
			obj.visible = showLabels;
			obj.position.copy( position );
			this.regionLabels.add( obj );
            // and fetch number of region from perm number dict.
            // also add R_num
            this.braid_group.add(this.regionLabels);
        }
        return valid_regions;
    }

    logits ( dataset ) {
        let acts = [];
        for (let p of dataset.points) {
            const px = p.position.x;
            const py = p.position.y;
            const pz = p.position.z;
            let scores = [];
            for (let w of this.weights) {
                // let dot = px * w.x + py * w.y + pz * wz;
                let dot = px * w.x + py * w.y + pz * w.z;
                scores.push(dot);
            }
            acts.push(scores);
        }
        return acts;
    }

    classify ( dataset ) {
        let softmax_probs = [];
        for (let p of dataset.points) {
            const px = p.position.x;
            const py = p.position.y;
            const pz = p.position.z;
            let scores = [];
            for (let w of this.weights) {
                // let dot = px * w.x + py * w.y + pz * wz;
                let dot = px * w.x + py * w.y + pz * w.z;
                scores.push(dot);
            }
            let probs = MATH.softmax ( scores );
            softmax_probs.push(probs);
        }
        return softmax_probs;
    }

    get_direction( ) {
        let directions = [[], []]
        for (let w of this.weights ) {
            directions[0].push(w.x)
            directions[1].push(w.y)
        }
        // Compute 3D projection of 4D directions
        // (translate 2D plane from 4D to 3D)
        // This is currently the "projection" used for the simplex
        let projection = [[ 1.,  1.,  1.],
                          [ -1., -1., 1.],
                          [ -1.,  1.,  -1.],
                          [ 1.,  -1.,  -1.]];
        let transpose = [[], [], []];
        for (let i = 0; i < projection.length; i++ ) {
            for (let j = 0; j < projection[0].length; j++ ) {
                transpose[j].push(projection[i][j]);
            }
        }
        let dirs = [];

        for ( let i=0; i < directions.length; i++ ) {
            let xx = MATH.dotprod(transpose[0], directions[i]);
            let yy = MATH.dotprod(transpose[1], directions[i]);
            let zz = MATH.dotprod(transpose[2], directions[i]);
            let vec = new THREE.Vector3(xx, yy, zz);
            dirs.push(vec);
        }
        let cross = new THREE.Vector3();
        cross.crossVectors(dirs[0], dirs[1]);
        return cross
    }
}


function decideMouseView ( mouse ) {

    let targetView;
    const x = mouse.x;
    const y = window.innerHeight - mouse.y;

    views.forEach(function (view) {

        const left = window.innerWidth * view.left;
        const bottom = window.innerHeight * view.bottom;
        const width = window.innerWidth * view.width;
        const height = window.innerHeight * view.height;

        if ( (x > left) && (x < (left + width)) ) {
            if ( (y > bottom) && (y < (bottom + height)) ) {
                targetView = view;
            }
        }
    });

    return targetView;
}

function classifyPoints( ) {
    allViews[defaultView].classify();
    weightMatrix.update();
}

function onMouseMove( event ) {

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    mouse_orig.x = event.clientX;
    mouse_orig.y = event.clientY;
    currentView = decideMouseView( {x: event.clientX, y: event.clientY} );

    if ( currentView !== undefined ) {
        mouse.x = ( (event.clientX - currentView.left) / ( currentView.width * window.innerWidth ) ) * 2 - 1;
        mouse.y = - ( (event.clientY - currentView.bottom) / ( currentView.height * window.innerHeight ) ) * 2 + 1;

        if ( selected !== null ) {
            let camera = currentView.camera;
            const origin = new THREE.Vector3(0, 0, 0);
            const dist = origin.distanceTo(camera.position);
            const x = mouse.x * dist;
            const y = mouse.y * dist;
            let position = new THREE.Vector3();
            position.x = x;
            position.y = y;
            position.z = 0;

            const xrad = mouse.x * Math.PI / 2;
            const yrad = mouse.y * Math.PI / 2;

            let target = new THREE.Vector3();
            camera.getWorldDirection(target);

            let zaxis = new THREE.Vector3(0, 0, -1);

            const rotation = new THREE.Quaternion();

            rotation.setFromUnitVectors( zaxis, target.normalize());

            // Project onto sphere
            const rad = 3.;
            position.x = rad * Math.sin(xrad);
            position.y = rad * Math.cos(xrad) * Math.sin(yrad);
            position.z = rad * Math.cos(xrad) * Math.cos(yrad);
            //rho * math.cos(latitude) * math.cos(longitude),
            //rho * math.cos(latitude) * math.sin(longitude),
            //rho * math.sin(latitude)
            
            // Compute position on hemisphere (imagine a dome)
            position.applyQuaternion(rotation);

            position = position.projectOnPlane(target);
            if (dimension < 3) {
                position.z = 0;
            }
            if (dimension < 2) {
                position.y = 0;
            }

            selected.move_to(position);
            classifyPoints();
        }
    }
}

function onMouseDown( event ) {
    dragging = true;
    selected = ws.pick(mouse, views[0].scene, views[0].camera);
}

function onMouseUp( event ) {
    dragging = false;
    selected = null;
}

function render() {
    views.forEach(function (view) {
        const left = Math.floor( window.innerWidth * view.left );
        const bottom = Math.floor( window.innerHeight * view.bottom );
        const width = Math.floor( window.innerWidth * view.width );
        const height = Math.floor( window.innerHeight * view.height );

        renderer.setViewport( left, bottom, width, height );
        renderer.setScissor( left, bottom, width, height );
        renderer.setScissorTest( true );
        renderer.setClearColor( view.background );
        renderer.render( view.scene, view.camera );
        renderer.localClippingEnabled = true;

        // view.point_light.position.copy( view.camera.position );

        labelRenderer.setSize(left, window.innerHeight - height - bottom, width, height);
        labelRenderer.render( view.scene, view.camera );
    });
}


function animate() {
    requestAnimationFrame(animate);
    // playScrollAnimations();
    currentView = decideMouseView( mouse_orig );
    if ( currentView !== undefined ) {
        if ( currentView.controls !== undefined ) {
            currentView.controls.update();
        }
    }
    render();
}


window.addEventListener('resize', () => {

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio( window.devicePixelRatio );

    labelRenderer.setSize(window.innerWidth, window.innerHeight);

    views.forEach(function (v) {
        v.camera.aspect = ( v.width * window.innerWidth ) / ( v.height * window.innerHeight );
        v.camera.updateProjectionMatrix();
    });
});

window.addEventListener( 'pointerup', onMouseUp );
window.addEventListener( 'pointerdown', onMouseDown );
window.addEventListener( 'pointermove', onMouseMove );


function fetchNumClass ( ) {
	let span = document.getElementById('num-class');
	let value = parseInt(span.innerHTML);
	return value;
}


function fetchDimension ( ) {
	let span = document.getElementById('num-dim');
	let value = parseInt(span.innerHTML);
	return value;
}


class WeightMatrixVis {
    constructor (divid) {
        this.divid = divid;
        this.div = document.getElementById(divid); 
        // this.generateMatrix();
        this.generateMatrix = this.generateMatrix.bind(this);
        this.update = this.update.bind(this);
    }

    generateMatrix( ){
        let table = document.createElement('div');
        table.className = 'matrix';
        this.div.appendChild(table);
        this.table = table;
    }

    update () {
        this.table.innerHTML = '';
        for (let w of ws.weights) {
            let tr = document.createElement('tr');
            for (let i = 0; i < dimension; i++){
                let color = new THREE.Color(w.color);
                let td = document.createElement('td');
                if (i == 0) {
                    td.innerText = Math.round(w.x * 100) / 100;
                }
                else if (i == 1) {
                    td.innerText = Math.round(w.y * 100) / 100;
                }
                else {
                    td.innerText = Math.round(w.z * 100) / 100;
                }
                td.className = 'matrixcell';
                td.style = 'color: #' + color.getHexString() + ';' ;
                tr.appendChild(td);
            }
            tr.className = 'matrixRow';
            this.table.appendChild(tr);
        }
    }
}


class DropdownMenu {
    constructor (buttonId, options) {
        this.toggled = false;
        this.buttonId = buttonId;
        this.options = options;
        this.onClick = this.onClick.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.button = document.getElementById(buttonId);
        this.optionList = document.getElementById('menu-options');
        this.button.addEventListener('click', this.onClick);
        this.appendOptions();
    }

    onClick ( ) {
        if (this.toggled) {
            document.getElementById('menu').classList.add('invisible');
            this.toggled = false;
        }
        else {
            document.getElementById('menu').classList.remove('invisible');
            this.toggled = true;
        }
    }

    selectOption ( e ) {
        let label = e.target.innerText;
        let option = null;
        for (let key of Object.keys(this.options)) {
            let opt = this.options[key];
            if (opt.label == label) {
                opt.selected = true;
                defaultView = key;
            }
            else {
                opt.selected = false;
            }
        }
        this.optionList.innerHTML = '';
        this.appendOptions();
        this.onClick();
		cleanUp();
        init(numClasses, dimension, defaultView);
    }

    appendOptions ( ) {
        for (let opt of Object.values(this.options)) {
            let label = opt.label;
            if (label) {
                if (! opt.selected) {
                    let link = document.createElement('a');
                    link.className = 'text-gray 700 block px-4 py-2 text-sm';
                    link.innerText = opt.label;
                    link.id = opt.cssid;
                    link.role = 'menuitem';
                    link.addEventListener('click', this.selectOption);
                    this.optionList.appendChild(link);
                }
                else {
                    this.button.innerHTML = opt.label + '<span class="chevron bottom"></span>';
                    this.button.id = opt.cssid;
                }
            }
        }
    }
}


// document.getElementById('increment-class').addEventListener( 'click', function ( ) {
// 	let span = document.getElementById('num-class');
// 	let value = fetchNumClass();
// 	if ( value < 4 ) {
// 		numClasses = value + 1;
// 		span.innerHTML = numClasses;
// 		cleanUp();
//         if ( comparisons !== null ) {
//             comparisons.clearHTML();
//             comparisons = new ComparisonButtons(numClasses);
//             comparisons.generateHTML();
//         }
// 		init(numClasses, dimension, defaultView);
// 	}
// });
//
//
// document.getElementById('decrement-class').addEventListener( 'click', function ( ) {
// 	let span = document.getElementById('num-class');
// 	let value = fetchNumClass();
// 	if ( value > 2 ) {
// 		numClasses = value - 1;
// 		span.innerHTML = numClasses;
// 		cleanUp();
//         if ( comparisons !== null ) {
//             comparisons.clearHTML();
//             comparisons = new ComparisonButtons(numClasses);
//             comparisons.generateHTML();
//         }
// 		init(numClasses, dimension, defaultView);
// 	}
// });


// document.getElementById('increment-dim').addEventListener( 'click', function ( ) {
// 	let span = document.getElementById('num-dim');
// 	let value = fetchDimension();
// 	if ( value < 2 ) {
// 		dimension = value + 1;
// 		span.innerHTML = dimension;
// 		cleanUp();
// 		init(numClasses, dimension, defaultView);
// 	}
// });
//
//
// document.getElementById('decrement-dim').addEventListener( 'click', function ( ) {
// 	let span = document.getElementById('num-dim');
// 	let value = fetchDimension();
// 	if ( value > 1 ) {
// 		dimension = value - 1;
// 		span.innerHTML = dimension;
// 		cleanUp();
// 		init(numClasses, dimension, defaultView);
// 	}
// });

document.addEventListener('keydown', (e) => {
    if ( e.key == '0' ) {
        window.scrollTo(0, 1);
    }
    if ( e.key == 'K' ) {
        if ( comparisons === null ) {
            comparisons = new ComparisonButtons(numClasses);
            comparisons.generateHTML();
        }
        else {
            comparisons.clearHTML();
            comparisons = null;
        }
    }
    if ( e.key == 'L' ) {
        if ( showLabels ) {
            showLabels = false;
            if (ws.regionLabels !== undefined) {
                ws.regionLabels.children.forEach( function (c) {
                    c.visible = false;
                });
            }
        }
        else {
            showLabels = true;
            classifyPoints();
        }
    }
});


let allViews = {
    'inputView': {
        left: 0,
        bottom: 0,
        width: 1.,
        height: 1.0,
        background: new THREE.Color(0x191919),
        eye: [ .01, -.01, 3.8 ],
        up: [ 0, 1, 0 ],
        fov: screen.width > 1280 ? 80 : 100,
        classify: function(view) {
            ws.braid_update();
            braid = ws.braid_weights;
            if ( showRegions ) {
                let labels = ws.getValidRegions( braid );
            }
            let logits = ws.logits(dataset);
            for ( let i = 0; i < dataset.length(); i++) {
                let scores = logits[i];
                let argm = MATH.argmax( scores );
                // set winner's color to data point
                dataset.points[i].material.color = new THREE.Color(ws.weights[argm].color);
                // dataset.points[i].material.clippingPlanes = [ws.weights[0].hyperplane.get_plane()];
            }
        },
    },
};


let dragging = false;
// let dropdown = new DropdownMenu('dropdown-button', allViews);
let weightMatrix = new WeightMatrixVis('softmax-label');
weightMatrix.generateMatrix();
let selected = null;
let colors = new Colors(); 
let defaultView = 'inputView';

let renderer, labelRenderer;

const mathjax = new RegionNums( MATH.factorial( 4 ) );
const mj = mathjax.generateMathJax();
document.getElementById("permanent").appendChild(mj);

let container = document.getElementById("container");
let braidButton;
let showRegions = false;
let showLabels = false;
let comparisons = null;

let numClasses = fetchNumClass();
let dimension = fetchDimension();

let views, currentView;
let dataset, ws;
let simplex, perm, braid;
let simplexPoints;

const mouse = new THREE.Vector2();
const mouse_orig = new THREE.Vector2();


function defineAnimations() {

    animationScripts = [];

    animationScripts.push({
        start: 0,
        end: 10,
        func(perc) {
            if ( perc < .3 ) {
                ws._weights.visible = false;
                for (const e of document.getElementsByClassName('w-label')) {
                    e.style.visibility = "hidden";
                }
                dataset._points.visible = false;
                document.getElementById('softmax-label').style.visibility = 'hidden';
            }
            else if ( perc < .82 ){
                for ( let k = 0; k < dataset.length(); k++) {
                    dataset.points[k].material.color = new THREE.Color(0x888888);
                }
                dataset._points.visible = true;
            }
            else {
                ws._weights.visible = true;
                for (const e of document.getElementsByClassName('w-label')) {
                    e.style.visibility = "";
                }
                if ( window.screen.width >= 1280 ) {
                    document.getElementById('softmax-label').style.visibility = '';
                }
            }
        }
    });

    animationScripts.push({
        start: 10,
        end: 20,
        func(perc) {
            if ( perc < .35 ) {
                for ( let k = 0; k < dataset.length(); k++) {
                    dataset.points[k].material.color = new THREE.Color(0x888888);
                }
            }
            else {
                classifyPoints();
            }
        }
    });

    animationScripts.push({
        start: 20,
        end: 30,
        w: {... ws.weights[1]},
        func(perc) {
            if (perc > .33) {
                let current = new THREE.Vector3(this.w.x, this.w.y, this.w.z);
                let target = new THREE.Vector3(2, 0, 0);
                current.lerp(target, (perc - .33) * 1.33);
                ws.weights[1].move_to({x:current.x, y:current.y, z:current.z});
                classifyPoints();
            }
        }
    });

    animationScripts.push({
        start: 30,
        end: 38,
        func(perc) {
            if (perc > .5) {
                if (dimension == 1 && numClasses == 3) {
                    dimension = 2;
                    numClasses = 4;
                    document.getElementById('num-dim').innerHTML = dimension;
                    document.getElementById('num-class').innerHTML = numClasses;
                    cleanUp();
                    if ( comparisons !== null ) {
                        comparisons.clearHTML();
                        comparisons = new ComparisonButtons(numClasses);
                        comparisons.generateHTML();
                    }
                    init(numClasses, dimension, defaultView);
                }
            }
            else {
                if (dimension == 2 && numClasses == 4) {
                    dimension = 1;
                    numClasses = 3;
                    document.getElementById('num-dim').innerHTML = dimension;
                    document.getElementById('num-class').innerHTML = numClasses;
                    cleanUp();
                    if ( comparisons !== null ) {
                        comparisons.clearHTML();
                        comparisons = new ComparisonButtons(numClasses);
                        comparisons.generateHTML();
                    }
                    init(numClasses, dimension, defaultView);
                    ws.weights[1].move_to({x:2, y:0, z:0});
                    classifyPoints();
                }
            }
        }
    });

    animationScripts.push({
        start: 38,
        end: 50,
        w: {... ws.weights[3]},
        func(perc) {
            let current = new THREE.Vector3(this.w.x, this.w.y, this.w.z);
            let target_a = new THREE.Vector3(.2, -1, 0);
            let target_b = new THREE.Vector3(-1, .2, 0);
            let target_c = new THREE.Vector3(1.1, .7, 0);
            if (perc < .33) {
                current.lerp(target_a, perc * 3);
            } else if (perc < .66 ) {
                current = new THREE.Vector3(target_a.x, target_a.y, target_a.z);
                current.lerp(target_b, (perc - .33) * 3 );
            } else {
                current = new THREE.Vector3(target_b.x, target_b.y, target_b.z);
                current.lerp(target_c, (perc - .66) * 3 );
            }
            ws.weights[3].move_to({x:current.x, y:current.y, z:current.z});
            classifyPoints();
        }
    });

    animationScripts.push({
        start: 50,
        end: 70,
        func(perc) {
            if (perc > .55) {
                if ( !showRegions ) {
                    showRegions = true;
                    ws.showBraid();
                    // if ( allViews[defaultView].ba !== undefined ) {
                    //     allViews[defaultView].ba.visible = true;
                    // }
                }
            }
            else {
                if ( showRegions ) {
                    showRegions = false;
                    ws.hideBraid();
                    // if ( allViews[defaultView].ba !== undefined ) {
                    //     allViews[defaultView].ba.visible = false;
                    // }
                }
            }
        }
    });

    animationScripts.push({
        start: 60,
        end: 70,
        func(perc) {
            if (perc > .55) {
                if ( !showLabels ) {
                    showLabels = true;
                    classifyPoints();
                    // if ( allViews[defaultView].ba !== undefined ) {
                    //     allViews[defaultView].ba.visible = true;
                    // }
                }
            }
            else {
                if ( showLabels ) {
                    showLabels = false;
                    classifyPoints();
                    // if ( allViews[defaultView].ba !== undefined ) {
                    //     allViews[defaultView].ba.visible = false;
                    // }
                }
            }
            if ( perc > .7 ) {
                let regions = document.getElementsByClassName('region-label');
                for ( let r of regions ) {
                    let c = r.children[0];
                    if ( c.id == "regionNum19" ) {
                        r.style.border = 'solid 3px';
                        r.style.borderColor = 'cyan';
                    }
                }
            }
            if ( perc > .95 ) {
                let regions = document.getElementsByClassName('region-label');
                for ( let r of regions ) {
                    let c = r.children[0];
                    if ( c.id == "regionNum19" ) {
                        r.style.border = 'solid 1px';
                        r.style.borderColor = 'white';
                    }
                }
            }
        }
    });

    animationScripts.push({
        start: 70,
        end: 75,
        func(perc) {
            if ( comparisons === null && this.activated === false ) {
                comparisons = new ComparisonButtons(numClasses);
                comparisons.generateHTML();
                this.activated = true;
            }
            else if ( comparisons !== null && this.activated === false ) {
                comparisons.clearHTML();
                comparisons = null;
                this.activated = false;
            }
        }
    });

    animationScripts.push({
        start: 70,
        end: 81,
        w: {... ws.weights[3]},
        func(perc) {

            if ( perc > .2 ) {
                let btn = document.getElementById('btn-d-a');
                if ( ! btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }
            if ( perc < .2 ) {
                let btn = document.getElementById('btn-d-a');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }

            if ( perc > .4 ) {
                let btn = document.getElementById('btn-d-b');
                if ( ! btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }
            if ( perc < .4 ) {
                let btn = document.getElementById('btn-d-b');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }

            if ( perc > .6 ) {
                let btn = document.getElementById('btn-d-c');
                if ( ! btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }
            if ( perc < .6 ) {
                let btn = document.getElementById('btn-d-c');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }

            if ( perc > .95 ) {
                let btn = document.getElementById('btn-d-a');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                }
                btn = document.getElementById('btn-d-b');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                }
                btn = document.getElementById('btn-d-c');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                }
            }
        }
    });

    animationScripts.push({
        start: 82,
        end: 85,
        w: {... ws.weights[3]},
        func(perc) {
            let last = new THREE.Vector3(this.w.x, this.w.y, this.w.z);
            let target_a = new THREE.Vector3(1.1, .7, 0);
            let current = new THREE.Vector3(target_a.x, target_a.y, target_a.z);
            current.lerp(last, perc);
            ws.weights[3].move_to({x:current.x, y:current.y, z:current.z});
            classifyPoints();
        }
    });

    animationScripts.push({
        start: 85,
        end: 93,
        clicked_a: false,
        clicked_b: false,
        clicked_c: false,
        w: {... ws.weights[3]},
        func(perc) {

            if ( perc > .25 && ! this.clicked_a ) {
                let btn = document.getElementById('btn-d-a');
                if ( ! btn.classList.contains('toggled') ) {
                    btn.click();
                    this.clicked_a = true;
                }
            }
            if ( perc < .25 && this.clicked_a ) {
                let btn = document.getElementById('btn-d-a');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                    this.clicked_a = false;
                }
            }

            if ( perc > .5 && ! this.clicked_b ) {
                let btn = document.getElementById('btn-d-b');
                if ( ! btn.classList.contains('toggled') ) {
                    btn.click();
                    this.clicked_b = true;
                }
            }
            if ( perc < .5 && this.clicked_b ) {
                let btn = document.getElementById('btn-d-b');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                    this.clicked_b = false;
                }
            }

            if ( perc > .75 && ! this.clicked_c ) {
                let btn = document.getElementById('btn-d-c');
                if ( ! btn.classList.contains('toggled') ) {
                    btn.click();
                    this.clicked_c = true;
                }
            }
            if ( perc < .75 && this.clicked_c ) {
                let btn = document.getElementById('btn-d-c');
                if ( btn.classList.contains('toggled') ) {
                    btn.click();
                    this.clicked_c = false;
                }
            }
        }
    });

    animationScripts.push({
        start: 95.5,
        end: 100,
        func(perc) {
            if ( perc > .1 ) {
                for (let e of document.getElementsByClassName('w-label')) {
                    e.style.visibility = 'hidden';
                    showLabels = false;
                    classifyPoints();
                }
            }
            else {
                for (let e of document.getElementsByClassName('w-label')) {
                    e.style.visibility = '';
                    showLabels = true;
                    classifyPoints();
                }
            }
        }
    });

    for ( let i = 0; i < animationScripts.length; i++ ) {
        let e = animationScripts[i];
        e.activated = scrollPercent > e.start;
    }
}


function cleanUp() {
	let children = [...container.children];
	for ( let c of children ) {
		if ( c.id !== 'permanent' ) {
			container.removeChild(c);
		}
	}
	for ( let v of views ) {
		let children = [...v.scene.children];
		for ( let c of children ) {
			v.scene.remove(c);
		}
	}
}


function init(numClasses, dim, defaultView) {

	renderer = new THREE.WebGLRenderer({antialias: true});
	renderer.setClearColor("#e5e5e5");
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.setPixelRatio( window.devicePixelRatio );

	labelRenderer = new CSS2DRenderer();
	labelRenderer.setSize( window.innerWidth, window.innerHeight );
	labelRenderer.domElement.style.position = 'absolute';
	labelRenderer.domElement.style.top = '0px';
	labelRenderer.domElement.style.pointerEvents = 'none';

	container.appendChild(renderer.domElement);
	container.appendChild(labelRenderer.domElement);

    // braidButton = document.createElement( 'button' );
    // braidButton.active = false;
    // braidButton.id = 'data-button';
    // braidButton.innerHTML = 'Insight from our paper';

	// container.appendChild( braidButton );

    // views = [allViews['inputView'], allViews[defaultView]];
    views = [allViews['inputView']];

    for ( let ii = 0; ii < views.length; ++ ii ) {

        const view = views[ ii ];
        let camera;
        if ( view.fov !== undefined) {
            camera = new THREE.PerspectiveCamera( view.fov, (view.width * window.innerWidth) / (view.height * window.innerHeight), 1., 20. );
        }
        else {
            const height = view.height * window.innerHeight;
            const width = view.width * window.innerWidth;
            const scale = view.scale;
            camera = new THREE.OrthographicCamera( width / -scale, width / scale, height / scale, height / -scale , -100, 100 );
        }
        camera.position.fromArray( view.eye );
        camera.up.fromArray( view.up );
        view.controls = createControls( camera );
        view.camera = camera;
        view.scene = new THREE.Scene();
        const point_light = new THREE.PointLight( 0x3a3a3a, 5, 1000 );
        point_light.position.set( 10, 10, 10 );
        view.point_light = point_light;
        view.scene.add(point_light);
        const ambient_light = new THREE.AmbientLight( 0xa1a1a1 );
        view.ambient_light = ambient_light;
        view.scene.add( ambient_light );
    }

    const weights = [[-1, -1, .5],
                     [.5, 1, 1],
                     [1., -.4, .2],
                     [.45, .1, .5]];
    
    const classLabels = ['Class a', 'Class b', 'Class c', 'Class d'];

    let basis = [];
    let wi = 0;

    for (let i=0; i < numClasses; i++) {
        if (dimension < 3) {
            weights[i][2] = 0;
        }
        if (dimension < 2) {
            weights[i][1] = 0;
        }
        const [a, b, c] = weights[i];
        basis.push(new WeightVector(a, b, c, colors.colors[i], classLabels[i]));
    }

    ws = new WeightQuiver(basis);
    ws.get_direction();
    ws.braid_group.visible = false;

    ws.add_to(views[0].scene);

    const NUM_ROWS = 100 * dim;
    dataset = new RandomDataset(NUM_ROWS, dim, .025);
    dataset.add_to(views[0].scene);

    ws.classify( dataset );

    perm = new Permutohedron([0, 0, 0], numClasses);

    // simplex = new Simplex([0, 0, 0], numClasses, .9, colors.colors);
    // allViews[defaultView].activate(views[1]);

    classifyPoints();

    ws.hideBraid();

    defineAnimations();
    animate();
}


init(numClasses, dimension, defaultView);
