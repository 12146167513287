export function argmax( l ) {
	let argmax = 0;
	let max = l[0];
	for ( let i = 1; i < l.length; ++i ) {
		if ( l[i] >  max ) {
			argmax = i;
			max = l[i];
		}
	}
	return argmax;
}

export function normalize ( arr ) {
    let l2_norm = 0;
    for (let i = 0; i < arr.length; i++ ) {
        l2_norm += arr[i] * arr[i]
    }
    l2_norm = Math.sqrt(l2_norm);
    for (let i = 0; i < arr.length; i++ ) {
        if ( l2_norm !== 0. ) {
            arr[i] = arr[i] / l2_norm
        }
    }
    return arr;
}

export function scale ( arr, scalar ) {
    for (let i = 0; i < arr.length; i++ ) {
        arr[i] = arr[i] * scalar;
    }
    return arr;
}

export function argsort ( arr ) {
    let idxs = range(arr.length);
    let zipped = arr.map(function(e, i) {
        return [e, idxs[i]];
    });
    zipped.sort(function(a, b) {
        if ( a[0] > b[0] ) {
            return 1;
        }
        else if ( a[0] < b[0] ) {
            return -1;
        }
        else {
            return 0;
        }
    });
    let args = [];
    for ( let elem of zipped ) {
        args.push(elem[1]);
    }
    return args
}

export function argsortPermutation( perm ) {
    const permLength = perm.length;
    let out = Array(permLength);
    for ( let i = 0; i < permLength; i++ ) {
        out[perm[i]] = i;
    }
	return out;
}

export function max( l ) {
	let max = l[0];
	for ( let i = 1; i < l.length; ++i ) {
		if ( l[i] >  max ) {
			max = l[i];
		}
	}
	return max;
}

export function softmax ( scores ) {
	const max_score = max(scores);
	let diff_scores = [];
	let denom = 0;
	for ( let i = 0; i < scores.length; ++i ) {
		const diff = scores[i] - max_score;
		diff_scores.push(diff);
		denom += Math.exp(diff);
	}

	let probs = [];
	for ( let i = 0; i < diff_scores.length; ++i ) {
		probs.push(Math.exp(diff_scores[i]) / denom);
	}
	return probs;
}

export function sum( l , length=undefined) {
	let len = length | l.length;
	total = 0;
	for ( let i = 0; i < len; ++i ) {
		total += l[i];
	}
	return total;
}

export function dotprod( a, b, length=undefined ) {
	let len = length || a.length;
	let dotprod = 0;
	for ( let i = 0; i < len; ++i ) {
		dotprod += a[i] * b[i];
	}
	return dotprod;
}

export function range ( val ) {
	return Array.from(Array(val).keys());
}

export function factorial ( val ) {
	if ( val > 1 ) {
		return val * factorial ( val - 1 );
	}
	return 1;
}

export function permToInversionString ( p ) {
    let pp = p.split(',');
    for (let i = 0; i < pp.length; i++) {
        pp[i] = parseInt(pp[i]);
    }

    let bitstring = [];
    for (let i = 0; i < pp.length; i++) {
        for (let j = i+1; j < pp.length; j++) {
            if ( pp[i] > pp[j] ) {
                bitstring.push(1);
            }
            else {
                bitstring.push(0);
            }
        }
    }
    return bitstring;
}

export function permutations(length) {
    // Taken from
    // https://stackoverflow.com/questions/9960908/permutations-in-javascript
    const permutation = range(length);
    let result = [permutation.slice()];
    let c = new Array(length).fill(0);
    let i = 1;
    let k, p;

    while ( i < length ) {
        if ( c[i] < i ) {
            k = i % 2 && c[i];
            p = permutation[i];
            permutation[i] = permutation[k];
            permutation[k] = p;
            ++c[i];
            i = 1;
            result.push(permutation.slice());
        } else {
            c[i] = 0;
            ++i;
        }
    }
    return result;
}
