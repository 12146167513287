class Colors {
    constructor () {
        this.red = 0xcd533b;
        this.green = 0x30a147;
        this.blue = 0x0075a2;
        this.yellow = 0xfaa916;
        this.purple = 0x6a5b6e;
        this.teal = 0x7dcfb6;
        this.darkblue = 0x555599;
        this.darkred = 0x995555;
        this.darkgreen = 0x559955;
        this.white = 0xf1f6f6;
        this.black = 0x1a1a1a;
        this.colors = [
                       this.blue,
                       this.yellow,
                       this.green,
                       this.red,
                       this.purple,
                       this.teal];
    }
}

export { Colors };
